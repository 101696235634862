<template>
  <div>
    <template v-if="templateIndex===1">
      <Header></Header>
    </template>
    <template v-else-if="templateIndex ==2">
      <Header1 />
    </template>
    <template v-else-if="templateIndex ==3">
      <Header2 />
    </template>
    <router-view></router-view>
  </div>
  <!-- <template v-if="templateIndex===1">
    <Foot></Foot>
  </template>
  <template v-else-if="templateIndex ==2">
    <Foot1 />
  </template>
  <template v-else-if="templateIndex ==3">
    <Foot2 />
  </template> -->
</template>

<script>
// 模板一
import Header from '@/components/Header.vue'
import Foot from '@/components/Foot.vue'

// 模板二
import Header1 from '@/components1/Header.vue'
import Foot1 from '@/components1/Foot.vue'

// 模板三
import Header2 from '@/components2/Header.vue'
import Foot2 from '@/components2/Foot.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
  components:{
    Header,
    Foot,

    Header1,
    Foot1,

    Header2,
    Foot2
  },
  setup(){
    const store = useStore();
    const templateIndex = computed(()=>{
      return store.state.templateIndex
    })
    return{
      templateIndex
    }
  }
}
</script>
<style lang="scss">

</style>
