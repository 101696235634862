import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

let host = window.location.host.replace("www.", "");
let templeteIndex = parseInt(Math.random()*3+ 1)
if(host == 'yukekj.cn'){
  templeteIndex =  1
}
else if(host == 'yunzhengxx.cn'){
  templeteIndex =  3
}
else if(host == 'chenxixx.cn'){
  templeteIndex =  2
} else if (host == 'yyqkj.top') {
  templeteIndex =  1
}
export default createStore({
  state: {
    companyName:'',
    record_num:'',
    email: '',
    templateIndex:templeteIndex,
    menuIndex:0,
    icon:`https://www.bestkids.net/wechat-miniprogram-static/%E8%83%8C%E6%99%AF%E5%9B%BE/template1/logo/logo_${'0'+(host == 'yyqkj.top' ? 1 : parseInt(Math.random()*8+1))}%402x.png`
  },
  getters: {
  },
  mutations: {
    setCompany(state, payload) {
      state.companyName = payload.companyName;
      state.record_num = payload.record_num;
      state.email = payload.email,
      state.address = payload.address;
      state.intro = payload.intro;
      state.title = payload.title
    },
    setMenuIndex(state, payload){
      state.menuIndex = payload
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({storage:window.sessionStorage})]
})
