import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import("@/views/home/HomeIndex.vue")
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('@/views/product/ProductIndex.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
