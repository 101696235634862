<template>
    <header class="header">
        <div class="content">
            <div class="l-con">
                <img :src="`https://www.bestkids.net/wechat-miniprogram-static/%E8%83%8C%E6%99%AF%E5%9B%BE/template1/logo/logo_${'0'+(parseInt(Math.random()*8+1))}%402x.png`" />
                <div class="company-name">{{companyName}}</div>
            </div>
            <div class="r-con">
                <view class="item" v-for="(item,index) in menuList" :key="index" :class="activeIndex===index?'active':''" @click="changeIndex(index)">{{item.name}}</view>
            </div>
        </div>
    </header>
</template>
<script>
import { ref,reactive,computed } from 'vue'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
export default {
    setup(){
        const router = useRouter()
        let companyName = computed(()=>{
            return store.state.companyName
        })
        const store = useStore()
        let menuList = reactive([
            {
                id:1,
                name:'官网首页'
            },
            {
                id:2,
                name:'产品介绍'
            }
        ])
        let activeIndex = computed(()=>{
            return store.state.menuIndex
        });
        let host = computed(()=>{
            return window.location.host.replace("www", "")
        })
        const changeIndex = (index) =>{
            if(index ===0){
                router.push({
                    path:'/'
                })
            }
            else{
                if(host.value === '54yks.cn'){
                    location.href = "./xifengyinlu/index.html"
                }
                router.push({
                    path:'/product'
                })
            }
            store.commit("setMenuIndex", index)
        }
        return{
            companyName,
            activeIndex,
            menuList,
            changeIndex
        }
    }
}
</script>
<style lang="scss" scoped>
    .header{
        width: 100%;
        height: 71px;
        background: #44D7B6;
        position: fixed;
        top:0px;
        left:0px;
        z-index: 1000;
        .content{
            margin: 0 auto 0;
            width: 1200px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .l-con{
                display: flex;
                align-items: center;
                height: 100%;
                gap:27px;
                img{
                    width: 40px;
                    height: 35px;
                }
                .company-name{
                    font-size: 17px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 24px;
                }
            }
            .r-con{
                display: flex;
                align-items: center;
                gap:100px;
                height: 100%;
                .item{
                    width: 114px;
                    height: 43px;
                    border-radius: 21px;
                    font-size: 15px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 43px;
                    cursor: pointer;
                    text-align: center;
                }
                .active{
                    background: #fff;
                    color: #44D7B6;
                }
            }
        }
    }
</style>