<template>
    <div class="content">
        <div class="beian-con">
            Copyright © 2016-2022
            <span class="company-name">{{companyName}}</span>
            保留所有权利 
            <a href="https://beian.miit.gov.cn" class="beian">{{record_no}}</a></div>
        <div style="margin-left: 50px;" class="beian-con"><span class="company-name">联系我们：{{ email }}</span></div>
    </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
    setup(){
        const store = useStore()
        const companyName = computed(()=>{
            return store.state.title
        })
        const record_no = computed(()=>{
            return store.state.record_num
        })
        const email = computed(() => {
            return store.state.email
        })
        return{
            companyName,
            record_no,
            email
        }
    }
}
</script>
<style scoped lang="scss">
    .content{
        width: 100%;
        height: 154px;
        display: flex;
        align-items: center;
        justify-content: center;
        .beian-con{
            color: #000B33;
            position: relative;
            margin-top: 38px;
            a{
                text-decoration: none;
                color: #000B33;
            }
        }
        .beian-con:after{
            content: '';
            width: 1200px;
            height: 1px;
            background: #000B33;
            opacity: 0.15;
            position: absolute;
            top: -30px;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }
</style>